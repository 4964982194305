import React, { useState } from 'react'
import 'react-dates/lib/css/_datepicker.css'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { getLabels } from '../redux/reducers/configuration'
import { checkDateIsBlocked } from './dates/Dates'

import generateId from '../utilities/helpers/generateId'

import { isEmpty } from 'lodash'

import '../styles/scss/components/query-reservation-card.scss'

import Tabs from './Tabs'
import NewReservationForm from './forms/NewReservationForm'

const defaultRooms = {
  [generateId()]: { adults: 2, children: 0 }
}

const utfDiff = 660 - moment().utcOffset()
const now = moment().add(utfDiff, 'minutes')
const tomorrow = now.add(1, 'days')

const generateInitialValues = initialOptions => {
  if (initialOptions) {
    const result = {}
    const {
      minimumNights,
      propertyCode,
      arrivalDate,
      rooms,
      defaultStayLength = 3
    } = initialOptions
    result.propertyCode = propertyCode || null

    let start =
      arrivalDate && moment(arrivalDate, 'YYYY-MM-DD').isAfter(now)
        ? moment(arrivalDate, 'YYYY-MM-DD')
        : tomorrow
    let end = start.clone().add(minimumNights || defaultStayLength, 'days')

    // check if start, end or anything in between are blocked
    const totalDays = end.diff(start, 'days')

    let hasBlockedDay = false
    for (let i = 1; i < totalDays; i++) {
      if (checkDateIsBlocked(start.clone().add(i, 'days'), propertyCode)) {
        hasBlockedDay = true
        break
      }
    }

    result.arrivalDate = hasBlockedDay ? null : start
    result.departureDate = hasBlockedDay ? null : end

    if (rooms?.length) {
      result.rooms = rooms.reduce((obj, room) => {
        obj[generateId()] = room
        return obj
      }, {})
    } else {
      result.rooms = defaultRooms
    }

    result.promoCodes = !isEmpty(initialOptions.promoCode)
      ? [initialOptions.promoCode.toUpperCase()]
      : []

    result.roomTypeCode = !isEmpty(initialOptions.roomTypeCode)
      ? initialOptions.roomTypeCode.toUpperCase()
      : ''

    if (!isEmpty(initialOptions.tourIds)) {
      result.tourIds = initialOptions.tourIds.toString()
    }

    return result
  } else {
    const start = checkDateIsBlocked(tomorrow) ? null : tomorrow
    const end =
      start && !checkDateIsBlocked(start.clone().add(2, 'days'))
        ? start.clone().add(2, 'days')
        : null

    return {
      arrivalDate: start,
      departureDate: end,
      rooms: defaultRooms,
      promoCodes: [],
      propertyCode: '',
      roomTypeCode: '',
      tourIds: [],
      spaIds: []
    }
  }
}

const ReservationCard = () => {
  const labels = useSelector(getLabels)

  const initialOptions =
    JSON.parse(localStorage.getItem('vitQueryCardParams')) || {}

  const [values, setValues] = useState(generateInitialValues(initialOptions))

  const [selectedTab, setSelectedTab] = useState('hotels')
  const jumpEndBy =
    initialOptions?.minimumNights ?? initialOptions?.defaultStayLength ?? 3
  // this is deprecated in consumer
  const isTrade = true

  const formProps = {
    initialOptions,
    values,
    onValuesChange: handleValuesChange,
    onSubmit: submit,
    selectedTab,
    jumpEndBy
  }

  const tabs = [
    {
      id: 'hotels',
      name: 'Hotels',
      visible: true,
      content: <NewReservationForm {...formProps} allowMultiRoom />
    },
    {
      id: 'campground',
      name: 'Campground',
      visible: !isTrade,
      content: <NewReservationForm {...formProps} isCampground allowMultiRoom />
    },
    {
      id: 'tours',
      name: 'Tours',
      visible: true,
      content: <NewReservationForm {...formProps} />
    }
  ]

  function handleValuesChange(values) {
    setValues(values)
  }

  function submit() {
    const params = new URLSearchParams()
    const isCampground = selectedTab === 'campground'
    const {
      arrivalDate,
      departureDate,
      rooms,
      promoCodes,
      propertyCode,
      roomTypeCode,
      tourIds,
      spaIds
    } = values

    params.append('startDate', arrivalDate.format('YYYY-MM-DD'))
    params.append('endDate', departureDate.format('YYYY-MM-DD'))
    params.append(
      'rooms',
      Object.values(rooms ?? {})
        .map(
          room =>
            `${room.adults}|${room.children}${
              isCampground && !!room.infants ? `|${room.infants}` : ''
            }`
        )
        ?.join(',') || '2|0'
    )

    if (promoCodes.length > 0) {
      params.append('promoCodes', promoCodes.join(','))
    }

    if (!isEmpty(propertyCode)) {
      params.append('hotelCode', propertyCode)
    }

    if (!isEmpty(roomTypeCode)) {
      params.append('roomTypeCode', roomTypeCode)
    }

    if (!isEmpty(spaIds)) {
      params.append('spaIds', spaIds)
    }

    if (!isEmpty(tourIds)) {
      params.append('tourIds', tourIds)
    }

    if (isCampground) {
      window.location = `${
        process.env.REACT_APP_CAMPGROUND_TARGET_URL
      }?${params.toString()}`
      return
    }

    if (isTrade) {
      params.append('flow', selectedTab)
    } else {
      if (selectedTab === 'tours') {
        params.append('flow', 'E')
      } else {
        params.append('flow', 'AE')
      }
    }

    const paramString = params.toString()

    let basePath = isTrade
      ? process.env.REACT_APP_TRADE_TARGET_URL
      : process.env.REACT_APP_TARGET_URL

    if (initialOptions?.targetUrl) {
      basePath = initialOptions?.targetUrl
    }

    if (isTrade) {
      const entryPoint = selectedTab === 'tours' ? '/tours' : '/accommodations'
      basePath += entryPoint
    } else {
      const entryPoint = selectedTab === 'tours' ? '/tours' : ''
      basePath += entryPoint
    }
    window.location = `${basePath}?${paramString}`
  }

  return (
    <>
      <div
        className={`query-reservation-card ${
          initialOptions && initialOptions.widgetType
            ? initialOptions.widgetType
            : ''
        }`}
        data-testid="query-card"
      >
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
        />

        {initialOptions && initialOptions.displayCardFooter && (
          <div className="query-reservation-card-footer">
            <p className="medium">{labels.assistanceNeededCopy}</p>
            <p className="large">1300 134 044</p>
            <p className="small">
              <span className="block">To speak with a friendly</span>
              <span className="block">Voyages representative.</span>
            </p>
          </div>
        )}
      </div>
    </>
  )
}

export default ReservationCard
